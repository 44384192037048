@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;



.profile{
    padding: 0px 2rem 2rem;

    &__backarrow{
      padding-left: 1rem;
    }

    &__img-wrap{
        margin-left: 10px;
        cursor: pointer;

        &:hover{
            margin-left: -7px;
            transition-duration: 1s;
        }
    }
    &__arrow{
      width: 20px;
      height: 20px;
      margin: 1rem 0.7rem;
    }
    &__logo{
      display: flex;
      justify-content: center;
    }
    
    &__logo-img{
      width: 160px;
      transition-property: transform;
      // transition-duration: 1s;

      // &:hover{
      //   transform: rotateY(180deg);
      // }
    }
    &__header{
        color: $secondary;
        font-weight: 600;
        font-size: 40px;
        margin: 0px 0px 1rem;
        letter-spacing: 1.5px;
    }
    &__body{
        color: $secondary;
        font-weight: 300;
        margin: 0px 0px 2.5rem;
        letter-spacing: 1.2px;
        font-size: 14px;
        text-align: justify;
    }
    &--description{
        color: burlywood;
        font-weight: 500;
    }
    &__education{
        color: $secondary;
        font-weight: 600;
        font-size: 1.5rem;
        letter-spacing: 1.2px;
        text-decoration: underline;
    }
    &__skills{
      color: $secondary;
      font-weight: 600;
      letter-spacing: 1.2px;
      font-size: 1.5rem;
      text-decoration: underline;
      margin: 3rem 0px 1.2rem;
    }
    &__edu-wrap{
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:hover {
          cursor: pointer;
            .profile__check{
                transform: rotateY(180deg);
                transition-duration: 2s;
              }
         }
    }
    &__check{
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    &__school{
        color: $secondary;
        font-weight: 300;
        font-size: 1rem;
        letter-spacing: 1.2px;
        margin: 0.5rem;
    }
    &__cert{
        color: burlywood;
    }


    &__icons-wrap{
        width:100%;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        align-content: center;
    }
    &__icon-name-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 45px;
        margin: 5px 40px 40px 0px;
    }

    &__icons{
        width: 1.8rem;
        height: 30px;
        margin-bottom: 3px;

        // &:hover{
        //     margin-bottom: -20px;
        //     transition-duration: 2s;
        //     cursor: pointer;
        // }
    }

    &__icon-name{
        color:white;
        font-size: 14px;
    }
    

      @include tablet-up{
          padding: 1.8rem 4.8rem;

      }

      @include laptop-up{
          padding: 0px 11.25rem;

          &__logo-img:hover{
              transform: rotateY(360deg);
              transition-duration: 3s;
          }
          &__body{
              font-size: 16px;
          }
      }


}


