@use './partials/typography' as *;
@use './partials/variables' as *;
@use './partials/mixins' as *;

* {
    box-sizing: border-box;
}


body {
    color: $primary;
    font-family: "Futura";
    margin: 0;
    padding: 0;
    border: 0;
    background-color: rgba(128, 128, 128, 0.897);
    text-shadow: 0px 3px 7px black;
}

a,
p,
button,
input,
label{
    font-family: "Futura";
}



