@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;


.projects{
    height: 100vh;

    &__header{
        padding: 2rem 2rem;
        margin: 1.5rem 1.5rem 2.5rem;
        border-radius: 1rem;
        box-shadow: 1px 1px 1rem 4px #000;
        background-color: black;
        display: flex;
        justify-content: flex-start;
        align-content: center;
    }
    &__head{
        font-size: 26px;
        width: 90%;
        display: flex;
        justify-content: center;
        font-weight: bold;
        color: burlywood;

    }

    &__arrow{
        width: 20px;
        height: 20px;

        &:hover{
            margin-left: -7px;
            transition-duration: 1s;
            cursor: pointer;
        }
    }

    &__backarrow{
        display: flex;
        align-items: center; 
        width: 5%;
    }
    &__num1{
        background-color: grey;
    }
    &__num2{
        background-color: cadetblue;  
    }
    &__details{
        padding: 2rem 2rem;
        margin: 1.5rem;
        border-radius: 1rem;
        border: 1px solid #000;
        box-shadow: 1px 1px 1rem 4px #000;
        background-color:black;

        &:hover {
            cursor: pointer;
            transform: scale(1.06);
            
                .projects__title{
                        font-size: 1.8rem;
                        transition-duration: 1s;
                    }
                .projects__content{
                        // font-size: 1.2rem;
                        transition-duration: 1s;
                }
        }

    }
    &__title{
        color: burlywood;
        margin: 0px;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1.5px;
    }
    &__title-underline{
        width: 50px;
        height: 2px;
        background-color: rgb(247, 215, 175);
        display: block;
        margin-bottom: 10px;
    }
    &__content{
        margin: 10px 0px 20px;
        font-family: 300;
        letter-spacing: 1px;
        color: white;
    }
    &__repo-link{
        text-decoration: none;
        padding: 0.2rem 0.8rem;
        margin-bottom: 1rem;
        color: #fff;
        border-radius: 10rem;
        border: 2px solid #fff;
        display: inline-block;

        &:hover{
            // color: #0000;
            background-color: burlywood;

        }
    }

    &__icons-wrap{
        width:100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }
    &__icon-name-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 45px;
        margin: 0px 23px 15px 0px;
    }
    &__icons{
        width: 2rem;
        height: 1.5rem;
        margin-bottom: 3px;
        // margin: 5px 10px 5px 5px;

        // &:hover{
        //     margin-bottom: -20px;
        //     transition-duration: 2s;
        //     cursor: pointer;
        // }
    }
    &__icon-name{
        color: white;
        font-size: 14px;
    }


    @include tablet-up{

        &__header{
            margin: 2.5rem 4.8rem 3rem;
        }
        &__details{
            margin: 1.8rem 4.8rem;
        }

    }

    @include laptop-up{
        &__header{
            margin: 3rem 11.25rem 4rem;
        }
        &__details{
            margin: 2rem 11.25rem;
        }
    }


}



