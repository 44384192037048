@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;


.main-page{
    box-sizing: border-box;
    height: 100vh;
    
    &__logo-wrap{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0px 0px;
        height: 30vh;
    }
    &__logo{
        width: 150px;
        border-radius: 50%;
        opacity: 0;
        animation-name: mylogo;
        animation-duration: 3s;
        animation-delay: 5s;
        animation-fill-mode: forwards;
    }
    &__content{
        height: 70vh;
    }
    &__top{
        height: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &__middle{
        height: 35%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        opacity: 0;
        animation-name: mymiddle;
        animation-duration: 3s;
        animation-delay: 3s;
        animation-fill-mode: forwards;

    }
    &__bottom{
        height: 15%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        opacity: 0;
        animation-name: mybottom;
        animation-duration: 3s;
        animation-delay: 4s;
        animation-fill-mode: forwards;
    }
    &__name{
        color: $secondary;
        text-shadow: 1px 0px 1px;
        letter-spacing: 1.5px;
        font-size: 12px;
        height: 10%;
        display: flex;
        align-items: center;
        position: relative;
        animation: myname;
        animation-duration: 3s;
        animation-fill-mode: forwards;
    
    }
    &__title{
        color: burlywood;
        text-shadow: 1px 0px 1px;
        letter-spacing: 2px;
        font-size: 19px;
        height: 20%;
        display: flex;
        align-items: flex-start;
        position: relative;
        animation: mytitle;
        animation-duration: 3s;
        animation-fill-mode: forwards;
    }
    &__about{
        color: white;
        padding: 0px 2rem 0px;
        letter-spacing: 2px;
        margin: 0px;
        height: 60%;
        display: flex;
        align-items: flex-start;
        position: relative;
        animation: myabout;
        animation-duration: 3s;
        animation-fill-mode: forwards;
    }
    &__menu{
        color: $secondary;
        text-decoration: none;
        @include header;
        letter-spacing: 0.3rem;

        &:hover{
            transform: rotateY(360deg);
            transition-duration: 3s;
        }
    }
    &__divider{
        background-color: burlywood;
        height: 1px;
        width: 80%;
    }
    &__divid{
        margin-bottom: 0px;
    }
    &__contact{
        color: burlywood;
        font-size: 12px;
        letter-spacing: 2px;
        text-decoration: none;
    }
    &__links{
        width: 40px;
        height: 40px;
        opacity: 0.8;

        &:hover{
            cursor: pointer;
            transition-duration: 1s;
            opacity: 1;
            transform: scale(1.5);
        }
    }


    @include tablet-up{

        &__about{
            width:80%;
            padding: 0px;
            font-size: 1.1rem;
        }
        &__bottom{
            width: 70%;
            margin: 0px auto;
        }

    }

    @include laptop-up{
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding: 0px 11.25rem;

        &__logo-wrap{
            width: 50%;
            margin: 0px;
            justify-content: right;
        }
        &__logo{
            width: 18rem;
        }
        &__content{
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            
        }
        &__top{
            align-items: flex-start;
        }
        &__name{
            font-size: 2.25rem;
            line-height: 1.125;
            margin-bottom: 0.5rem;
            letter-spacing: 0.2em;
        }
        &__title{
            font-size: 0.75rem;
            letter-spacing: 0.5em;
            align-items: center;
        }
        &__about{
            width: 100%;
        }
        &__underline{
            width: 80px;
            height: 2px;
            background-color:#f37272;
            display: block;
            margin: 30px 0px;
        }
        &__middle{
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            border-top: 1px solid burlywood;
            border-bottom: 1px solid burlywood;
            height: 20%;
        }
        &__divider{
            display: none;
        }
        &__bottom{
            justify-content: space-around;
        }
        &__contact{
            margin-right: 20px;
        }

    }


}

@keyframes myname{
    0% {top: -50px;}
    100% {top: 0;}
}

@keyframes mytitle{
    0% {top: -50px;}
    100% {top: 0;}
}

@keyframes myabout{
    0% {left: -1000px;}
    100% {left: 0;}
}

@keyframes mymiddle{
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes mybottom{
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes mylogo{
    0% {opacity: 0;}
    100% {opacity: 1;}
}




