$primary: #000000; // Global text color
$secondary: #ffffff; // Global White color
$background-color:  rgb(245, 245, 245);
$body-background: #d1d1d1;
$cta-button:rgb(8, 55, 107); //for buttons
$dividers-borders: #bdc5d5; 



// Device breakpoints
$mobile: 320px;
$tablet: 768px;
$laptop: 1280px;
$desktop: 1440px;


