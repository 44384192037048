

@font-face {
    font-family: 'Futura';
    src: url('../../assets/fonts/FuturaLT/FuturaLT.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Futura';
    src: url('../../assets/fonts/FuturaLT/FuturaLT-Oblique.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Futura';
    src: url('../../assets/fonts/FuturaLT/FuturaLT-CondensedBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Futura';
    src: url('../../assets/fonts/FuturaLT/FuturaLT-Bold.ttf') format('truetype');
    font-weight: 700;
}



